@import '../../../../scss/theme-bootstrap';

.header-offers-banner-formatter {
  display: block;
  height: $offerbar-mobile-height;
  min-height: $offerbar-mobile-height;
  overflow: hidden;
  padding: 0 20px;
  position: relative;
  width: 100%;
  background-color: $color-black;
  @include breakpoint($landscape-up) {
    height: $offerbar-height;
    min-height: $offerbar-height;
    padding: 0 $container-pad-l;
  }
  .gnav-offers-hidden & {
    display: none;
    height: 0;
    min-height: 0;
  }
  &__carousel {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding-#{$ldirection}: 40px;
    padding-#{$rdirection}: 40px;
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: $container-pad-l;
      padding-#{$rdirection}: $container-pad-l;
    }
    &-item {
      display: none;
      .not-initialized &:first-child,
      &.item-active {
        display: block;
      }
      .fade & {
        animation-name: fade;
        animation-duration: 1.5s;
      }
    }
    &-btn {
      a {
        background: 0 0;
        border: none;
        height: 15px;
        position: absolute;
        width: 15px;
        top: 50%;
        transform: translate(-50%, -50%);
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
      .icon {
        color: $color-black;
        fill: $color-black;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0;
      }
      .icon--caret--left {
        #{$ldirection}: 5px;
        text-align: $ldirection;
        @include breakpoint($landscape-up) {
          #{$ldirection}: 5%;
        }
      }
      .icon--caret--right {
        #{$rdirection}: 5px;
        text-align: $rdirection;
        @include breakpoint($landscape-up) {
          #{$rdirection}: 5%;
        }
      }
    }
  }
  &__arrow-prev {
    #{$ldirection}: 20px;
    @include breakpoint($landscape-up) {
      #{$ldirection}: 5%;
    }
  }
  &__arrow-next {
    #{$rdirection}: 20px;
    @include breakpoint($landscape-up) {
      #{$rdirection}: 5%;
    }
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    #{$rdirection}: -10px;
    a,
    svg {
      #{$rdirection}: 0;
    }
    .icon {
      fill: $color-white;
      height: 15px;
      width: 15px;
      stroke-width: 30px;
      position: relative;
      #{$rdirection}: auto;
    }
  }
  $units: 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $value in $units {
    &.min-height-#{$value} {
      min-height: #{$value}px;
    }
  }
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
